import "babel-polyfill";
/* Globals*/
const postURL = 'https://ol9z2t8we4.execute-api.us-west-2.amazonaws.com/pre-launch/early-access';
const proxyURL = 'https://cors-anywhere.herokuapp.com/';
const mainCTA = document.querySelector(".landing-main-cta");
const modalBox =  document.querySelector(".dark-modal-wrapper");
const emailSubmit = document.querySelector(".landing-cta-form");
const emailInput = document.querySelector(".landing-cta-input");
let modalCTAs = document.querySelectorAll('.modal-cta');
const modalClose = document.querySelector('.dark-modal-close'); 
const introTip = document.querySelector('.intro-tip'); 
const msgCTA = document.querySelector('.cta-form-msg'); 

/** Sleep promise */
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/** Team Photos */
const requestEarlyAccess = async (addMember, delMember) => {
    let earlyBox = document.querySelector(".early-access-box");
    //await sleep(500);
    /*founderImg.classList.add(addMember);
    await sleep(750);
    founderImg.classList.remove(delMember);*/
  };

  const removeMe = async (delMe) => {
    await sleep(500);
    delMe.classList.remove("me-active");
    delMe.classList.add("me-hidden");
    await sleep(300);
    delMe.classList.add("me-removed");
  };
  
  /** Only Show element helper */
  const showMe = async (showMe, ms) => {
    await sleep(ms - 200);
    showMe.classList.remove("me-removed");
    await sleep(ms);
    showMe.classList.add("me-active");
    showMe.classList.remove("me-hidden");
  };



 /* Early Access Submit */
emailSubmit.addEventListener("submit", (e) => {
    e.preventDefault();
    let submitDate = new Date().toISOString();

    let leadMail = emailInput.value;
    console.log(leadMail, submitDate);   
    const postDATA = {   
                        earlyAdopterMail: leadMail,
                        submitDate: submitDate
                     };
    emailInput.value = "Joining wait-list..";
    fetch(proxyURL + postURL, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(postDATA),
    })
    .then(response => response.json())
        .then(postDATA => {
        console.log('Success:', postDATA);
        removeMe(emailSubmit);
        showMe(modalBox,700);
        Array.from(modalCTAs).forEach(cta => {
          showMe(cta,500);      
        });

        })
        .catch((error) => {
        console.error('Error:', error);
        });
});


Array.from(modalCTAs).forEach(cta => {
    cta.addEventListener('click', function(event) {
        
        console.log('CTA Clicked : ',cta);
  });      
});

modalClose.addEventListener('click', function(e) {       
  removeMe(modalBox);
  Array.from(modalCTAs).forEach(cta => {
    removeMe(cta);      
    });
  showMe(msgCTA,700); 
  removeMe(introTip);  
});     